import React from 'react';
import {images} from "../../constants";

const Navigation = () => {
    return (
            <>
                <div className="navigation-menu">
                    <div className="inner">
                        <div className="side-menu">
                            <ul>
                                <li><a href="#services">Our services</a></li>
                                <li><a href="#gallery">Gallery</a></li>
                                <li><a href="#about">About us</a></li>
                                <li><a href="#values">Our Values</a></li>
                                <li><a href="#contact">Book us</a></li>
                            </ul>
                        </div>

                        <div className="sides">
                            <div id="map">
                            </div>
                            <figure>
                                <img src={images.slide3} alt="slide1"/>
                                <a href="#map" data-fancybox><img src={images.locator} alt="locator"/></a>
                            </figure>
                        </div>

                        <div className="sides">
                            <h2><strong>Yan Events </strong>
                               is an event agency based in kigali offering special services such as drummers, and all kind of performances.</h2>
                            <address>KK 5 Av 64 St<br/>
                                Kigali<br />
                                <a href="mailto:yanevents2@gmail.com">yanevents2@gmail.com</a>
                                +250 789 429 057
                            </address>
                        </div>
                    </div>
                </div>
            </>
    );
};

export default Navigation;
