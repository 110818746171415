import logo from '../assets/images/logo.png';
import logolight from '../assets/images/logo-light.png';
import dessin from '../assets/images/dessin.png';
import slide1 from '../assets/images/tambour1.jpg';
import slide2 from '../assets/images/tambour2.jpg';
import slide3 from '../assets/images/slide3.jpg';
import goTop from '../assets/images/icon-gotop.svg';
import locator from '../assets/images/icon-map-marker.svg';
import video from '../assets/videos/video2.mp4';
import presentation from '../assets/videos/presentation.mp4';
import videoHome from '../assets/videos/home.mp4';
import aboutpage1 from '../assets/images/fond-about.png';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    logo,
    logolight,
    dessin,
    slide1,
    slide2,
    goTop,
    locator,
    video,
    aboutpage1,
    slide3,
    presentation,
    videoHome,
};
