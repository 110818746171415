import React from 'react';
import {images} from "../../constants";

const Footer = () => {
    return (
            <>
                <footer className="footer">
                    <div className="container">
                        <div className="row">
                            {/*<div className="col-12">
                                <div className="career wow fadeInUp">
                                    <h6>CAREER OF AGENSY UKRAINE</h6>
                                    <h2>We allways looking for talented peoples, soo let's work together</h2>
                                    <div className="custom-link"><a href="#">Apply Now</a> <span/> <i/>
                                    </div>
                                </div>
                            </div>*/}
                            <div className="col-lg-5 wow fadeInUp">
                                <figure className="logo"><img src={images.logo} alt="logo"/></figure>
                            </div>
                            <div className="col-lg-4 col-md-6 wow fadeInUp">
                                <h5>Location</h5>
                                <address>
                                    Yan Events<br/>
                                    Nyarugunga, KK 5 Av 64 St Kigali<br/>
                                    Rwanda
                                </address>
                            </div>
                            <div className="col-lg-3 col-md-6 wow fadeInUp">
                                <h5>Say Hello</h5>
                                <address>
                                    yanevents2@gmail.com <br/>
                                    +250 789 429 057
                                </address>
                            </div>
                            <div className="col-12 wow fadeInUp">
                                <div className="sub-footer">
                                    <span>© {new Date().getFullYear()} YanEvents - All rights Reserved</span></div>
                            </div>
                        </div>
                    </div>
                </footer>
            </>
    );
};

export default Footer;
