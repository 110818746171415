import React from 'react';
import {Aside, Header} from "../components";
import Services from "../container/Services";
import Navigation from "../components/Navbar/Navigation";
import Footer from "../components/Footer/Footer";
import Gallery from "../container/Gallery";
import About from "../container/About";
import Contact from "../container/contact";
import Values from "../container/Values";
import VideoHome from "../container/Video-home";

const Home = () => {
    return (
            <>
                <Navigation />
                <Aside/>
                <Header/>
                <Services />
                <Gallery />
                <About />
                <Values />
                <VideoHome />
                <Contact />
                <Footer/>
            </>
    );
};

export default Home;
