import React, {useEffect, useState} from 'react';
import {client, urlFor} from "../client";

const Services = () => {

    const [services, setServices] = useState([]);

    useEffect(() => {
        const query = '*[_type == "services"]';
        client.fetch(query)
                .then((data) => {
                    setServices(data);
                })
    }, []);

    return (
            <>
                <section className="our-awards" id="services">
                    <div className="container">
                        <div className="titles">
                            <h6>SERVICES & FACILITIES</h6>
                            <p>We’re constantly refining our services. Adding new features. Working to
                                help your event shine.</p>
                        </div>
                        <ul>
                            {services.map((service, index) => (
                                    <li className="wow fadeInUp" data-wow-delay="0.10s" key={service.title + index}>
                                        <figure>
                                            <img src={urlFor(service.imgUrl)} alt={service.title}/>
                                        </figure>
                                        <h5>{service.title}</h5>
                                        <small>{service.description}</small>
                                    </li>
                            ))}
                        </ul>
                    </div>
                </section>
            </>
    );
};

export default Services;
