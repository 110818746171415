import React, {useEffect, useState} from 'react';
import {client, urlFor} from "../client";

const AboutPage = () => {

    const [aboutpages, setAboutpages] = useState([]);

    useEffect(() => {
        const query = '*[_type == "aboutpages"]';
        client.fetch(query)
                .then((data) => {
                    setAboutpages(data);
                })
    }, []);

    return (
            <>
                <section className="about-intro">
                    <div className="container">
                        {aboutpages.map((about, index) => (
                        <div className="row about-content" key={about.title + index}>
                            <div className="col-12">
                                <h2>{about.title}</h2>
                            </div>
                            <div className="col-md-6">
                                <img src={urlFor(about.imgUrl)} alt={about.title}/>
                            </div>
                            <div className="col-md-6">
                                <p>{about.description}</p>
                            </div>
                        </div>
                        ))}
                    </div>
                </section>
            </>
    );
};

export default AboutPage;
