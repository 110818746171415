import React from 'react';
import './css/index'
import {BrowserRouter, Route, Switch} from "react-router-dom";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import About from "./pages/About";
import {AnimatePresence} from "framer-motion";

const App = () => {
    return (
            <BrowserRouter>
                <AnimatePresence>
                    <Switch>
                        <Route path="/" exact component={Home}/>
                        <Route path="/about" exact component={About}/>
                        <Route component={NotFound}/>
                    </Switch>
                </AnimatePresence>
            </BrowserRouter>
    );
};

export default App;
