import React, {useEffect, useState} from 'react';
import {client, urlFor} from "../client";

const About = () => {

    const [abouts, setAbouts] = useState([]);

    useEffect(() => {
        const query = '*[_type == "abouts"]';
        client.fetch(query)
                .then((data) => {
                    setAbouts(data);
                })
    }, []);

    return (
            <>
                <section className="about-intro" id="about">
                    <div className="container">
                        {abouts.map((about, index) => (
                        <div className="row" key={about.title + index}>
                            <div className="col-12">
                                <h2>{about.title}</h2>
                                <p>{about.introduction}</p>
                            </div>
                            <div className="col-md-6">
                                <img src={urlFor(about.imgUrl)} alt={about.title}/>
                            </div>
                            <div className="col-md-6">
                                <p>{about.description}</p>
                                <div className="separator" />
                                <div className="custom-link"><a href={about.buttonlink}>{about.button}</a> <span/> <i/></div>
                            </div>
                        </div>
                                ))}
                    </div>
                </section>
            </>
    );
};

export default About;
