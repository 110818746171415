import React from 'react';
import {images} from "../../constants";

const Aside = () => (
        <aside className="left-side">
            <div className="logo">
                <img src={images.logo} alt="logo"/>
            </div>
            <ul>
                <li><a href="https://www.instagram.com/yaneventskigali/" target="_blank" rel="noreferrer">INSTAGRAM</a></li>
                <li><a href="https://www.facebook.com/Himbaza-Club-1750416818584080" target="_blank" rel="noreferrer">FACEBOOK</a></li>
                <li><a href="https://twitter.com/ClubHimbaza" target="_blank" rel="noreferrer">TWITTER</a></li>
            </ul>
            <a href="#top" className="gotop"><img src={images.goTop} alt="gotop"/></a>
        </aside>
);

export default Aside;
