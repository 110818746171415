import React, {useEffect, useState} from 'react';
import {client, urlFor} from "../client";

const Gallery = () => {

    const [galleries, setGalleries] = useState([]);

    useEffect(() => {
        const query = '*[_type == "galleries"]';
        client.fetch(query)
                .then((data) => {
                    setGalleries(data);
                })
    }, []);

    return (
            <>
                <section className="team" data-color="dark" id="gallery">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="titles">
                                    <h6>GALLERY</h6>
                                    <p>We’re constantly refining our services. Adding new features. Working to help your
                                        event shine.</p>
                                </div>
                            </div>
                            <div className="masonry-grid">
                                {galleries.map((gallery, index) => (
                                        <div className="masonry-grid-item" key={gallery.title + index}>
                                            <figure className="project-image reveal-effect masker wow">
                                                <a href={urlFor(gallery.imgUrl)} data-fancybox><img src={urlFor(gallery.imgUrl)} alt={gallery.title} /></a>
                                            </figure>
                                        </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </section>
            </>
    );
};

export default Gallery;
