import React, {useEffect, useState} from 'react';
import {client} from "../client";

const Values = () => {

    const [values, setValues] = useState([]);

    useEffect(() => {
        const query = '*[_type == "values"]';
        client.fetch(query)
                .then((data) => {
                    setValues(data);
                })
    }, []);

    return (
            <>
                <section className="work-with-us" data-color="dark" id="values">
                    <div className="container wow fadeInUp">
                        <h6>OUR VALUES</h6>
                        <div className="values-container">
                            {values.map((value, index) => (
                                    <h2 key={value.title + index}>{value.title}</h2>
                            ))}
                        </div>
                    </div>
                </section>
            </>
    );
};

export default Values;
