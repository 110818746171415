import React from 'react';
import images from "../constants/images";

const Video = () => {
    return (
            <>
                <section className="page-header">
                    <div className="video-bg">
                        <video src={images.presentation} loop autoPlay muted />
                    </div>
                </section>
            </>
    );
};

export default Video;
