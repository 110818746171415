import React from 'react';
import Navigation from "../components/Navbar/Navigation";
import {Aside, HeaderPage} from "../components";
import Video from "../container/Video";
import AboutPage from "../container/About-page";
import Footer from "../components/Footer/Footer";

const About = () => {
    return (
            <>
                <Navigation />
                <Aside/>
                <HeaderPage/>
                <Video />
                <AboutPage/>
                <Footer />
            </>
    );
};

export default About;
