import sanityClient from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';

export const client = sanityClient({
    projectId: '7p0f4q2o',
    dataset: 'production',
    apiVersion: '2022-02-01',
    useCdn: true,
    token: 'skgbXx8QBqhLoOrMLLfwOugqH7iNEIAlLXhETckUcpiVuasvJ4VJGZoL0xEnxbY9O1DkmHe5ua4nn4I7DtrB1jDvrFB6LBK8C6OK9sn4MQOPZ4NMGg5R7rYKT7CKDUxK4E6m4SdSh7HzGkMnVgmPhSl4ZppidDEiAocvnaZJsH8NL7F4K0ne',
});

const builder = imageUrlBuilder(client);

export const urlFor = (source) => builder.image(source);
