import React from 'react';
import images from "../constants/images";

const VideoHome = () => {
    return (
            <>
                <section className="showreel" data-color="dark">
                    <div className="container">
                        <div className="video reveal-effect masker wow">
                            <video src={images.videoHome} autoPlay loop muted />
                        </div>
                    </div>
                </section>
            </>
    );
};

export default VideoHome;
